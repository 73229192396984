<template>
    <v-layout column>
        <h3>Suggested Challenges</h3>
        <v-card>
            <v-list>
                <v-list-item v-for="(challenge, index) in challenges" :key="`challenge_${index}`">
                    <v-list-item-action>
                        <v-btn icon rounded @click="remove(challenge)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        {{ challenge.name }}
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-switch v-model = "challenge.enabled"  @change="update(challenge)"/>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item >
                    <v-list-item-action>
                        <v-btn icon rounded @click="add">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-text-field :error-messages="error" v-model="newChallenge" placeholder="Take off your shirt" label = "Suggested challenge" @change="inputChanged"/>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-layout>
</template>

<script>
    import { getSuggestedChallenges, createSuggestedChallenge, removeSuggestedChallenge, updateSuggestedChallenge } from '../../utils/api'
    export default {
        data() {
            return {
                challenges: [
                ],
                newChallenge: '',
                error: ''
            }
        },
        created() {
            this.fetch()
        },
        methods: {
            async fetch() {
                const { data } = await getSuggestedChallenges();
                this.challenges = data;
            },
            async add() {
                if (!this.newChallenge) {
                    this.error = 'Cannot be empty'
                    return;
                }
                const { data } = await createSuggestedChallenge({
                    name: this.newChallenge, 
                    enabled: true
                });
                this.challenges.push(data)
                this.newChallenge = ""
            },
            inputChanged() {
                this.error = ''
            },
            async remove(challenge) {
                await removeSuggestedChallenge(challenge.id);
                const index = this.challenges.indexOf(challenge);
                this.challenges.splice(index, 1);
            },
            async update(challenge) {
                await updateSuggestedChallenge(challenge.id, {
                    name: challenge.name, 
                    enabled: challenge.enabled
                });
            }
        },
        watch: {

        }
    }
</script>

<style lang="scss" scoped>

</style>